import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { UseStyles } from 'hooks';
import { CardElementComponent } from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';
import { PurchaseCardInfoOutput } from 'services/api/client';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { FormControl, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { CreditCardIcon, GooglePayIcon, ApplePayIcon } from 'theme/icons';
import { Checkbox, TextField } from '@components/Form';
import custom from 'theme/custom';
import stripeJs from '@stripe/stripe-js';
import clsx from 'clsx';

type Props = {
  CardElement: CardElementComponent;
  setPayWithWallet: (status: boolean) => void;
  isCardChecked: boolean;
  idNoChargeChecked: boolean;
  isPayingWithWallet: boolean;
  enabledWalletMethod?: string;
  card: PurchaseCardInfoOutput | null | undefined;
};

export function PaymentOption({
  setPayWithWallet,
  CardElement,
  isCardChecked,
  idNoChargeChecked,
  isPayingWithWallet,
  enabledWalletMethod,
  card,
}: Props): JSX.Element {
  const classes = UseStyles();
  const { dispatch } = UseCheckoutDetails();
  const { errors, clearErrors } = useFormContext();
  useEffect(() => {
    if (idNoChargeChecked || isCardChecked || (enabledWalletMethod && isPayingWithWallet)) {
      clearErrors('card_token');
    }
  }, [idNoChargeChecked, isCardChecked, enabledWalletMethod, isPayingWithWallet]);
  const onChangeHandler = (event: stripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) dispatch({ type: 'S_PAYMENT_INFO_ENTERED', payload: { paymentMethod: event.brand, step: 2 } });
  };
  return (
    <div className={`${classes.box} ${classes.completeInformationBlock}`}>
      <Typography component='h3' variant='h3'>
        Choose your payment method
      </Typography>
      {!idNoChargeChecked && card && (
        <Checkbox label={`Use the credit card on file ending in ${card.last4}`} name='cardCheck' />
      )}
      {!idNoChargeChecked && !isCardChecked && !enabledWalletMethod && (
        <>
          <TextField
            label='Name on credit card'
            name='billing_name'
            defaultValue=''
            placeholder='John Smith'
            error={!!errors.billing_name}
            InputProps={{
              classes: {
                root: 'outlinedInputRoot',
                notchedOutline: 'outlinedInputNotchedOutline',
              },
            }}
          />
          <FormControl error={!!errors.card_token} className={classes.fieldWrapper} fullWidth>
            <label className={classes.fieldLabel}>Credit card number</label>
            <CardElement
              className={classes.cardElement}
              onFocus={() => {
                clearErrors('card_token');
              }}
              onChange={onChangeHandler}
              options={{
                hidePostalCode: true,
                iconStyle: 'solid',
                style: {
                  base: {
                    iconColor: custom.slate,
                    color: custom.slate,
                    '::placeholder': {
                      color: custom.lightText,
                    },
                  },
                  invalid: {
                    color: custom.orange,
                  },
                },
              }}
            />
            {errors.card_token && <FormHelperText error>{errors.card_token.message}</FormHelperText>}
          </FormControl>
        </>
      )}
      {!idNoChargeChecked && !isCardChecked && !!enabledWalletMethod && (
        <div className={classes.completeInformationMethods}>
          <div className={`${classes.completeInformationMethod} item-0 ${!isPayingWithWallet ? 'active' : ''}`}>
            <Button className={classes.completeInformationMethodBtn} onClick={() => setPayWithWallet(false)}>
              {!isPayingWithWallet ? (
                <RadioButtonCheckedIcon className='mark' />
              ) : (
                <RadioButtonUncheckedIcon className='mark' />
              )}
              <>
                <CreditCardIcon className='icon' />
                Credit or Debit Card
              </>
            </Button>
            {!isPayingWithWallet && (
              <div className={classes.completeInformationMethodContent}>
                <TextField
                  className={classes.fieldTextNoBorder}
                  label='Name on credit card'
                  name='billing_name'
                  placeholder='John Smith'
                  error={!!errors.billing_name}
                  InputProps={{
                    classes: {
                      root: 'outlinedInputRoot',
                      notchedOutline: 'outlinedInputNotchedOutline',
                    },
                  }}
                />
                <FormControl error={!!errors.card_token} className={classes.fieldWrapper} fullWidth>
                  <label className={classes.fieldLabel}>Credit card number</label>
                  <CardElement
                    className={clsx(classes.cardElement, classes.cardElementNoBorder)}
                    onFocus={() => {
                      clearErrors('card_token');
                    }}
                    onChange={onChangeHandler}
                    options={{
                      hidePostalCode: true,
                      iconStyle: 'solid',
                      style: {
                        base: {
                          iconColor: custom.slate,
                          color: custom.slate,
                          '::placeholder': {
                            color: custom.lightText,
                          },
                        },
                        invalid: {
                          color: custom.orange,
                        },
                      },
                    }}
                  />
                  {errors.card_token && <FormHelperText error>{errors.card_token.message}</FormHelperText>}
                </FormControl>
              </div>
            )}
          </div>
          <div className={`${classes.completeInformationMethod} item-1 ${isPayingWithWallet ? 'active' : ''}`}>
            <Button className={classes.completeInformationMethodBtn} onClick={() => setPayWithWallet(true)}>
              {isPayingWithWallet ? (
                <RadioButtonCheckedIcon className='mark' />
              ) : (
                <RadioButtonUncheckedIcon className='mark' />
              )}
              {enabledWalletMethod === 'apple-pay' ? (
                <ApplePayIcon className='applePayIcon' />
              ) : (
                <GooglePayIcon className='googlePayIcon' />
              )}
            </Button>
            {isPayingWithWallet && (
              <div className={classes.completeInformationMethodContent}>
                Another step will appear to securely submit your payment information.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
