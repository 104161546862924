import React, { PropsWithChildren } from 'react';
import styles from './FieldContainer.module.scss';
import { FieldContainerProps } from './FieldContainer.interface';
import UIFieldError from '../FieldError/FieldError';
import cn from 'classnames';
import { FieldError } from 'react-hook-form/dist/types';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import LinearProgress from '@mui/material/LinearProgress';

export const FieldContainer = ({
  description,
  errorAltText,
  error,
  helperText,
  helperTextTooltip,
  helperTextBottom,
  hidden,
  label,
  loading,
  required,
  noMargin,
  children,
  ...rest
}: PropsWithChildren<FieldContainerProps>): JSX.Element => {
  return (
    <div
      className={cn(styles.FieldContainer, {
        [styles.FieldContainerHasError]: !!error,
        error: !!error,
        [styles.FieldContainerNoMargin]: !!noMargin,
      })}
      style={hidden ? { display: 'none' } : undefined}
      {...rest}
    >
      <>
        {label && (
          <label className={styles.FieldContainerLabel}>
            {label}
            {required && <span> *</span>}
            {helperTextTooltip && (
              <Tooltip classes={{ tooltip: styles.helperTooltip }} placement='right' title={helperTextTooltip}>
                <HelpIcon className={styles.helperTooltipIcon} color='primary' />
              </Tooltip>
            )}
          </label>
        )}
        {helperText && (
          <div className={styles.helperText}>
            <HelpOutlinedIcon className={styles.helperTextIcon} />
            {helperText}
          </div>
        )}
        {description && <div className={styles.description}>{description}</div>}
      </>
      {children}
      {loading && <LinearProgress />}
      {error && <UIFieldError>{errorAltText || (error as FieldError | Error).message}</UIFieldError>}
      {helperTextBottom && <div className={styles.FieldContainerHelp}>{helperTextBottom}</div>}
    </div>
  );
};

export default FieldContainer;
